*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    background-color:  rgb(22, 22, 22);
    font-size: 1vh;
    color: white;
    /* cursor: none; */
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    z-index: 1000000;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
}

@media (orientation:portrait) {
    html {
        font-size: 1.25vw;
    }
}
@media (orientation:landscape) {
    html {
        font-size: 1.25vh;
    }
}

.webgl
{
    position: fixed;
    outline: none;
    box-sizing: border-box;
    /* border: 2px solid blue; */
    cursor: grab;
    z-index: 2000;
}

.section {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.episodes {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.doubleEpisodes {
    height: 100vh;
    width: 160vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 2px solid blue; */
    padding-left: 5vw;
    padding-right: 5vw;
}

.Bebas {
    font-family: 'Bebas Neue', cursive;
}

.landingText {
    width: 50vw;
    height: 100vh;
    margin-right: 60rem;
}

.mainTextDiv {
    height: 25%;
    width: 100%;
    text-align: center;
}

#mainTextDiv1 {
    font-size: 32rem;
    /* padding-bottom: 16rem; */
}

#mainTextDiv2 {
    font-size: 24.75rem;
    margin-top: 6rem;
}

#mainTextDiv3 {
    font-size: 10rem;
    height: 12%;
}

#mainTextDiv4 {
    font-size: 20rem;
    line-height: 80%;
}

.slider {
    width: 100vw;
    height: 300vh;
    position: absolute;
    background-color: transparent;
    z-index: 1000;
}

.Bungee {
    font-family: 'Bungee Hairline', cursive;
}

.favFrom {
    position: absolute;
    width: 30vw;
    height: 20vh;
    top: 0;
    left: 10vw;
    color: white;
    font-size: 4rem;
}

.episodeDetails {
    width: 48vw;
    height: 92.5vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: 1px solid white;
    padding-right: 2.5vw;
    padding-left: 2.5vw;
    /* background-color: rgba(255, 255, 255, 0.158);
    border-radius: 1rem; */
}

.episodeDetailsHalf {
    width: 48vw;
    height: 92.5vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* border: 2px solid red; */
    border: 2px solid white;
    padding-right: 2.5vw;
    padding-left: 2.5vw;
    /* background-color: rgba(255, 255, 255, 0.158);
    border-radius: 1rem; */
}

.episodeName {
    width: 100%;
    height: 15vh;
    font-size: 12rem;
    /* border: 2px solid blue; */
    white-space: nowrap; 
}

.volumeEpisode {
    width: 100%;
    height: 10vh;
    font-size: 4rem;
    /* text-align: right;  */
    /* border: 2px solid white; */
    display: flex;
    align-items: flex-end;
    padding-bottom: 1rem;
}

.picDiv {
    width: 100%;
    height: 52.5vh; 
}

.comment {
    width: 100%;
    height: 12.5vh;
    font-size: 4rem;
    /* border: 2px solid blue; */
    font-family: 'Roboto', sans-serif;
    text-align: right;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

/* Bad Travelling */
#ep1VE {
    justify-content: flex-end;
}

#ep1 {
    margin-left: 40vw;
}

#ep2VE {
    justify-content: flex-start;
}

#ep2 {
    margin-right: 40vw;
    /* border: 2px solid white; */
}

#ep3VE {
    justify-content: flex-end;
}

#ep3 {
    /* border: 2px solid red; */
}

/* Scroll Bar */
/* width */
::-webkit-scrollbar {
width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
background: #00000010;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #a30000;
border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #a30000;
}